enum Identificators {
  BUILD_A_DATA_STACK = 'GA_build_a_data_stack',
  CONTACT_US = 'GA_banner_contact_us',
  CONTACT_US_HEADER = 'GA_header_contact_us',
  CUSTOMISE_YOUR_SOLUTION = 'GA_customise_your_solution',
  DO_TECH_AUDIT = 'GA_banner_do_a_tech_audit',
  ENHANCE_YOUR_PLATFORM = 'GA_enhance_your_platform',
  ESTIMATE_A_PROJECT_BANNER = 'GA_banner_estimate_a_project',
  ESTIMATE_YOUR_PROJECT_HEADER = 'GA_header_estimate_your_project',
  GET_A_CONSULTATION_BANNER = 'GA_banner_get_a_consultation',
  GET_A_FREE_CONSULTATION_BANNER = 'GA_banner_get_a_free_consultation',
  GET_IN_TOUCH = 'GA_get_in_touch',
  GET_STARTED = 'GA_banner_get_started',
  GET_TECH_AUDIT = 'GA_banner_get_a_tech_audit',
  HEADER_GET_IN_TOUCH = 'GA_header',
  HIRE_DEVELOPERS = 'GA_hire_developers',
  HIRE_QA = 'GA_hire_QA_experts',
  HIRE_US_BANNER = 'GA_banner_hire_us',
  HIRING_CONTACT_US = 'GA_hiring_contact_us',
  LETS_TALK = 'GA_banner_lets_talk',
  MODERNIZE_YOUR_APP = 'GA_modernize_your_app',
  NAVIGATION_GET_IN_TOUCH = 'GA_nav_menu',
  FOOTER_GET_IN_TOUCH = 'GA_footer',
  SCHEDULE_A_CALL_BANNER = 'GA_banner_schedule_a_call',
  SCHEDULE_A_CALL_HEADER = 'GA_header_schedule_a_call',
  START_PROJECT = 'GA_banner_start_project',
  START_WITH_A_TECH_AUDIT = 'GA_start_with_a_tech_audit',
  SWG_OFFER = 'GA_swg_offer',
  TALK_TO_OUR_TEAM = 'GA_talk_to_our_team',
  WANT_DETAILS = 'GA_want_details',
  CALL_WITH_AI_EXPERTS = 'GA_call_with_ai_experts',
  HEADER_CALL_AI_EXPERTS = 'GA_header_call_ai_experts',
  HEADER_HIRE_SAAS_ENGINEERS = 'GA_header_hire_saas_engineers',
  CONTACT_US_FOR_MORE_CANDIDATES = 'GA_contact_us_for_more_candidates',
  LEARN_MORE_HEYPRACTICE = 'GA_learn_more_heypractice',
  LEARN_MORE_ADACT = 'GA_learn_more_adact',
}

export default Identificators;
